import React, { useEffect, useState } from 'react';
import './Ubicacion.css';
import { fetchUbicacionData } from '../../../Services/ubicacionServices';

const Ubicacion = () => {
    const [ubicacion, setUbicacion] = useState({
        Titulo: '',
        Descripcion: '',
        googleMapsUrl: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Cargar los datos de la ubicación desde el backend
    useEffect(() => {
        const loadUbicacionData = async () => {
            try {
                const data = await fetchUbicacionData();
                if (data.length > 0) {
                    setUbicacion(data[0]);
                } else {
                    setError(true);
                }
            } catch (error) {
                console.error('Error al cargar los datos de la ubicación:', error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        loadUbicacionData();
    }, []);

    return (
        <div className="ubicacion-container">
            <h2 className="ubicacion-title">Ubicación</h2>
            <div className="ubicacion-content">
                <div className="ubicacion-texto">
                    <h3>{ubicacion.Titulo || 'Dirección no disponible'}</h3>
                    <p>{ubicacion.Descripcion || 'Descripción no disponible.'}</p>
                </div>
                {loading ? (
                    <p>Cargando mapa...</p>
                ) : error ? (
                    <p>No se pudo cargar el mapa.</p>
                ) : (
                    <iframe
                        src={ubicacion.googleMapsUrl || 'https://www.google.com/maps'}
                        className="mapa"
                        allowFullScreen=""
                        loading="lazy">
                    </iframe>
                )}
            </div>
        </div>
    );
}

export default Ubicacion;
