import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LandingPage from './componentes/Pages/LandingPage';
import AdminDashboard from './componentes/Pages/AdminDashboard';
import Login from './componentes/Pages/Login';
import ProtectedRoute from './ProtectedRoute';  // Importamos el componente de rutas protegidas

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    
                    {/* Ruta protegida para el AdminDashboard */}
                    <Route path="/admin" element={
                        <ProtectedRoute>
                            <AdminDashboard />
                        </ProtectedRoute>
                    } />

                    <Route path="/login" element={<Login />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
