// src/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

// Verificamos si hay un token en el localStorage
const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token;  // Devuelve true si hay token, false si no
};

const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" />;  // Si no está autenticado, redirigimos al login
    }
    return children;  // Si está autenticado, mostramos el componente protegido
};

export default ProtectedRoute;
