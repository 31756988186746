import React, { useState } from 'react';
import CategoriaGaleriaAdmin from './CategoriaGaleriaAdmin';
import CategoriaProductoAdmin from './CategoriaProductoAdmin';
import CategoriaServiciosAdmin from './CategoriaServiciosAdmin';
import './ApartadoCategorias.css'; // Importamos el CSS

const ApartadoCategorias = () => {
    const [activeTab, setActiveTab] = useState('Galeria');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Galeria':
                return <CategoriaGaleriaAdmin />;
            case 'Productos':
                return <CategoriaProductoAdmin />;
            case 'Servicios':
                return <CategoriaServiciosAdmin />;
            default:
                return null;
        }
    };

    return (
        <div className="apartado-categorias">
            {/* Cartel con título */}
            <div className="header-container">
                <h1>Apartado de Categorías</h1>
            </div>

            {/* Botones de las categorías */}
            <div className="tabs">
                <button 
                    onClick={() => setActiveTab('Galeria')} 
                    className={`btn-tab ${activeTab === 'Galeria' ? 'active' : ''}`}
                >
                    Galería
                </button>
                <button 
                    onClick={() => setActiveTab('Productos')} 
                    className={`btn-tab ${activeTab === 'Productos' ? 'active' : ''}`}
                >
                    Productos
                </button>
                <button 
                    onClick={() => setActiveTab('Servicios')} 
                    className={`btn-tab ${activeTab === 'Servicios' ? 'active' : ''}`}
                >
                    Servicios
                </button>
            </div>

            {/* Contenido de las categorías */}
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default ApartadoCategorias;
