import React, { useState, useEffect, useRef } from 'react';
import { fetchTips } from '../../../Services/tipsServices';
import './Tips.css';

const Tips = () => {
    const [tips, setTips] = useState([]);
    const carruselRef = useRef(null);

    useEffect(() => {
        const loadTips = async () => {
            try {
                const data = await fetchTips();
                setTips(data);
            } catch (error) {
                console.error('Error fetching tips:', error);
            }
        };

        loadTips();
    }, []);

    const scrollLeft = () => {
        carruselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        carruselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    return (
        <div className="tips-section">
            <h2 className="tips-header">Tips de la Semana</h2>
            <p className="tips-subtitle">¡Descubre los mejores consejos para tu día a día!</p>
            <div className="carrusel-container">
                <button className="carrusel-button left" onClick={scrollLeft}>‹</button>
                <div className="tips-carrusel" ref={carruselRef}>
                    {tips.map((tip) => (
                        <div className="tip-card" key={tip.idTips}>
                            <img src={tip.Imagen_Url} alt={tip.Titulo} className="tip-imagen" />
                            <div className="tip-card-content">
                                <h3 className="tip-title">{tip.Titulo}</h3>
                                <p className="tip-description">{tip.Descripcion.substring(0, 150)}...</p>
                                <p className="tip-motivation">"{tip.Frase_D_Dia}"</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carrusel-button right" onClick={scrollRight}>›</button>
            </div>
        </div>
    );
};

export default Tips;
