import React, { useState, useEffect } from 'react';
import { fetchUbicacionData, updateUbicacionData } from '../../Services/ubicacionServices';
import './UbicacionAdmin.css';

// Definimos los límites para las validaciones
const MAX_WORDS_TITLE = 10;
const MAX_CHARACTERS_TITLE = 50;
const MAX_WORDS_DESCRIPTION = 60;
const MAX_CHARACTERS_DESCRIPTION = 250;

const UbicacionAdmin = () => {
  const [ubicacion, setUbicacion] = useState({
    idUbicacion: null,
    Titulo: '',
    Descripcion: '',
    googleMapsUrl: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadUbicacionData = async () => {
      try {
        const data = await fetchUbicacionData();
        if (data.length > 0) {
          setUbicacion(data[0]);
        } else {
          setUbicacion({
            idUbicacion: 1,
            Titulo: '',
            Descripcion: '',
            googleMapsUrl: ''
          });
        }
      } catch (error) {
        console.error('Error al cargar los datos de Ubicación:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUbicacionData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    let error = '';

    if (name === 'Titulo') {
      const words = newValue.split(' ').slice(0, MAX_WORDS_TITLE).join(' ');
      newValue = words.length <= MAX_CHARACTERS_TITLE ? words : newValue.slice(0, MAX_CHARACTERS_TITLE);
      if (words.split(' ').length > MAX_WORDS_TITLE || newValue.length > MAX_CHARACTERS_TITLE) {
        error = `Máximo ${MAX_WORDS_TITLE} palabras y ${MAX_CHARACTERS_TITLE} caracteres permitidos.`;
      }
    } else if (name === 'Descripcion') {
      const words = newValue.split(' ').slice(0, MAX_WORDS_DESCRIPTION).join(' ');
      newValue = words.length <= MAX_CHARACTERS_DESCRIPTION ? words : newValue.slice(0, MAX_CHARACTERS_DESCRIPTION);
      if (words.split(' ').length > MAX_WORDS_DESCRIPTION || newValue.length > MAX_CHARACTERS_DESCRIPTION) {
        error = `Máximo ${MAX_WORDS_DESCRIPTION} palabras y ${MAX_CHARACTERS_DESCRIPTION} caracteres permitidos.`;
      }
    }

    setUbicacion({ ...ubicacion, [name]: newValue });
    setErrors({ ...errors, [name]: error });
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      await updateUbicacionData(ubicacion);
      alert('Datos actualizados correctamente');
    } catch (error) {
      console.error('Error al actualizar los cambios:', error);
      alert('Error al actualizar los cambios');
    }
  };

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="ubicacion-admin-container">
      <h2 className="ubicacion-admin-title">Editar Ubicación</h2>
      <form className="ubicacion-form" onSubmit={handleSaveChanges}>
        <div className="form-group">
          <label htmlFor="Titulo">Título</label>
          <input
            type="text"
            name="Titulo"
            value={ubicacion.Titulo || ''}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Título"
          />
          {errors.Titulo && <p className="error-text">{errors.Titulo}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="Descripcion">Descripción</label>
          <textarea
            name="Descripcion"
            value={ubicacion.Descripcion || ''}
            onChange={handleInputChange}
            className="form-textarea"
            placeholder="Descripción"
          ></textarea>
          {errors.Descripcion && <p className="error-text">{errors.Descripcion}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="googleMapsUrl">URL de Google Maps</label>
          <input
            type="text"
            name="googleMapsUrl"
            value={ubicacion.googleMapsUrl || ''}
            onChange={handleInputChange}
            className="form-input"
            placeholder="URL de Google Maps"
          />
        </div>
        <button type="submit" className="save-button">Guardar Cambios</button>
      </form>
    </div>
  );
};

export default UbicacionAdmin;
