import React, { useState, useEffect, useRef } from 'react';
import './Servicios.css';
import ServicioModal from './ServicioModal';
import { fetchServicios } from '../../../Services/serviciosServices';

const Servicios = () => {
    const [modalServicio, setModalServicio] = useState(null);
    const [servicios, setServicios] = useState([]);
    const carruselRef = useRef(null);

    useEffect(() => {
        const obtenerServicios = async () => {
            try {
                const data = await fetchServicios();
                setServicios(data);
            } catch (error) {
                console.error('Error al obtener servicios:', error);
            }
        };

        obtenerServicios();
    }, []);

    const scrollLeft = () => {
        carruselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        carruselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    return (
        <div className="servicios">
            <h2 className="servicios-title">Nuestros servicios</h2>
            <p className="servicios-subtitle">Ven a ver todos los servicios ofrecidos por el salón de belleza de Mary</p>
            <div className="carrusel-container">
                <button className="carrusel-button left" onClick={scrollLeft}>‹</button>
                <div className="servicios-carrusel" ref={carruselRef}>
                    {servicios.map((servicio) => (
                        <div 
                            className="servicio-card" 
                            key={servicio.idServicio} 
                            onClick={() => setModalServicio(servicio)}
                        >
                            <img src={servicio.Imagen_Url} alt={servicio.NombreServicio} className="servicio-imagen" />
                            <div className="servicio-content">
                            <div className="servicio-categoria"> {servicio.CategoriasServicios?.NombreCategoriaServicio || 'Sin categoría'} </div>
                                <h3 className="servicio-title">{servicio.NombreServicio}</h3>
                                <p className="servicio-descripcion">{servicio.Descripcion.substring(0, 100)}...</p>
                                <a href="#ver-mas" className="servicio-link">Ver más</a>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carrusel-button right" onClick={scrollRight}>›</button>
            </div>
            {modalServicio && <ServicioModal servicio={modalServicio} onClose={() => setModalServicio(null)} />}
        </div>
    );
};

export default Servicios;
