import React, { useState, useEffect } from "react";
import {
  fetchUsuarios,
  createUsuario,
  updateUsuario,
  deleteUsuario,
} from "../../Services/usuariosServices"; // Ajusta según tu ruta
import "./UsuariosAdmin.css";

const UsuariosAdmin = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Username: "",
    Email: "",
    Contrasena: "",
  });
  const [editingUserId, setEditingUserId] = useState(null);

  // Cargar usuarios al iniciar el componente
  useEffect(() => {
    const loadUsuarios = async () => {
      try {
        const data = await fetchUsuarios();
        setUsuarios(data);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    };
    loadUsuarios();
  }, []);

  // Abrir modal para agregar usuario
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setFormData({ Username: "", Email: "", Contrasena: "" });
    setEditingUserId(null); // Asegúrate de que no está editando
  };

  // Cerrar modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Manejar cambios en el formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Guardar usuario
  const handleSaveUser = async (e) => {
    e.preventDefault();
    try {
      if (editingUserId) {
        // Actualizar usuario existente
        await updateUsuario(editingUserId, formData);
        setUsuarios((prevUsuarios) =>
          prevUsuarios.map((user) =>
            user.idUsuarios === editingUserId ? { ...user, ...formData } : user
          )
        );
      } else {
        // Crear nuevo usuario
        const newUser = await createUsuario(formData);
        setUsuarios([...usuarios, newUser]);
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error al guardar usuario:", error);
    }
  };

  // Eliminar usuario
  const handleDeleteUser = async (id) => {
    try {
      await deleteUsuario(id);
      setUsuarios(usuarios.filter((user) => user.idUsuarios !== id));
    } catch (error) {
      console.error("Error al eliminar usuario:", error);
    }
  };

  // Editar usuario
  const handleEditUser = (user) => {
    setEditingUserId(user.idUsuarios);
    setFormData({
      Username: user.Username,
      Email: user.Email,
      Contrasena: user.Contrasena || "",
    });
    setIsModalOpen(true);
  };

  return (
    <div className="usuarios-container">
      <div className="usuarios-header">
        <h2 className="usuarios-title">Gestión de Usuarios</h2>
        <button className="usuarios-add-btn" onClick={handleOpenModal}>
          Agregar Usuario
        </button>
      </div>

      <div className="usuarios-table">
        <table>
          <thead>
            <tr>
              <th>IDUsuario</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr key={usuario.idUsuarios}>
                <td>{usuario.idUsuarios}</td>
                <td>{usuario.Username}</td>
                <td>{usuario.Email}</td>
                <td>
                  <button
                    className="usuarios-edit-btn"
                    onClick={() => handleEditUser(usuario)}
                  >
                    Editar
                  </button>
                  <button
                    className="usuarios-delete-btn"
                    onClick={() => handleDeleteUser(usuario.idUsuarios)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="usuarios-modal-overlay">
          <div className="usuarios-modal">
            <h3 className="usuarios-modal-title">
              {editingUserId ? "Editar Usuario" : "Agregar Usuario"}
            </h3>
            <form onSubmit={handleSaveUser}>
              <div className="usuarios-form-group">
                <label>Nombre de Usuario:</label>
                <input
                  type="text"
                  name="Username"
                  value={formData.Username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="usuarios-form-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="usuarios-form-group">
                <label>Contraseña:</label>
                <input
                  type="password"
                  name="Contrasena"
                  value={formData.Contrasena}
                  onChange={handleInputChange}
                  required={!editingUserId}
                />
              </div>
              <div className="usuarios-modal-actions">
                <button type="submit" className="usuarios-save-btn">
                  Guardar
                </button>
                <button
                  type="button"
                  className="usuarios-cancel-btn"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsuariosAdmin;
