// src/Services/galeriaServices.js
import axios from 'axios';
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Función para obtener todas las imágenes de la galería
export const fetchGaleriaImagenes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/galeriaImagenes`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las imágenes de la galería:', error);
        throw error;
    }
};

// Función para crear una nueva imagen en la galería
export const createGaleriaImagen = async (imageData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/galeriaImagenes`, imageData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al crear una imagen en la galería:', error);
        throw error;
    }
};

// Función para eliminar una imagen de la galería
export const deleteGaleriaImagen = async (idGImagen) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/api/galeriaImagenes/${idGImagen}`);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar la imagen de la galería:', error);
        throw error;
    }
};
