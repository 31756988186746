import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./EscribenosModal.css";
import logo from "../../../assets/imagenes/logolmao.png";

const EscribenosModal = ({ isOpen, closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      setErrorMessage("Todos los campos son obligatorios.");
      return;
    }

    try {
      await emailjs.send(
        "service_c07uget", // Service ID
        "template_qxnroih", // Template ID
        {
          from_name: formData.name,
          reply_to: formData.email,
          message: formData.message,
        },
        "AAeeEoBwVVwUnZqA-" // Public Key
      );
      setIsSent(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      setErrorMessage("Error al enviar el mensaje. Intenta nuevamente.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="escribenos-modal-overlay">
      <div className="escribenos-modal">
        <div className="modal-header">
          <img src={logo} alt="Logo" className="modal-logo" />
          <h2>Escríbenos</h2>
        </div>
        {isSent ? (
          <div className="success-message">
            <p>¡Mensaje enviado con éxito! Nos pondremos en contacto contigo pronto.</p>
            <button onClick={() => { setIsSent(false); closeModal(); }}>Cerrar</button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Tu nombre"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Tu correo electrónico"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Escribe tu mensaje aquí"
              />
            </div>
            <div className="modal-actions">
              <button type="submit" className="send-button">
                Enviar
              </button>
              <button
                type="button"
                className="close-button"
                onClick={closeModal}
              >
                Cerrar
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EscribenosModal;
