// src/Services/contactosServices.js
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Función para obtener todos los contactos
export const fetchContactos = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/contactos`);
        if (!response.ok) {
            throw new Error('Error al obtener los contactos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching contactos:', error);
        throw error;
    }
};

// Función para actualizar un contacto por ID
export const updateContacto = async (id, data) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/contactos/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Error al actualizar el contacto');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating contacto:', error);
        throw error;
    }
};
