import React, { useState, useEffect } from 'react';
import { fetchCategorias } from '../../../Services/categoriaServices';
import { fetchGaleriaImagenes } from '../../../Services/galeriaServices';
import Modal from 'react-modal';
import './Galeria.css';

Modal.setAppElement('#root'); // Necesario para accesibilidad

const Galeria = () => {
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todos');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagenes, setImagenes] = useState([]);
    const [categorias, setCategorias] = useState([]);

    // Cargar categorías activas desde la base de datos
    useEffect(() => {
        const loadCategories = async () => {
            try {
                const data = await fetchCategorias(1); // Solo obtener categorías activas
                setCategorias(['Todos', ...data.map(cat => cat.NombreCategoria)]); // Agregar 'Todos' como opción predeterminada
            } catch (error) {
                console.error('Error fetching active categories:', error);
            }
        };

        const loadImages = async () => {
            try {
                const data = await fetchGaleriaImagenes(); // Cargar imágenes desde la base de datos
                setImagenes(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        loadCategories();
        loadImages();
    }, []);

    // Filtrar las imágenes según la categoría seleccionada
    const imagenesFiltradas = categoriaSeleccionada === 'Todos'
        ? imagenes
        : imagenes.filter(imagen => imagen.CategoriaImagenes?.NombreCategoria === categoriaSeleccionada);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="galeria">
            <h2 className="galeria-title">Galería de imágenes</h2>
            <p className="galeria-subtitle">Vea los resultados de nuestro trabajo</p>

            {/* Botones de categoría */}
            <div className="categorias">
                {categorias.map((categoria, index) => (
                    <button
                        key={index}
                        className={`categoria-button ${categoriaSeleccionada === categoria ? 'active' : ''}`}
                        onClick={() => setCategoriaSeleccionada(categoria)}
                    >
                        {categoria}
                    </button>
                ))}
            </div>

            {/* Galería de imágenes filtradas */}
            <div className="imagenes-grid">
                {imagenesFiltradas.length > 0 ? (
                    imagenesFiltradas.map((imagen, index) => (
                        <div className="imagen-container" key={index} onClick={() => openModal(imagen)}>
                            <img src={imagen.Imagen_Url} alt={imagen.Titulo} className="imagen" />
                        </div>
                    ))
                ) : (
                    <p>No hay imágenes en esta categoría.</p>
                )}
            </div>

            {/* Modal para mostrar la imagen ampliada */}
            {selectedImage && (
                <Modal 
                    isOpen={modalIsOpen} 
                    onRequestClose={closeModal} 
                    contentLabel="Imagen ampliada" 
                    className="react-modal"
                >
                    <h2>{selectedImage.Titulo}</h2> {/* Título de la imagen */}
                    <img src={selectedImage.Imagen_Url} alt={selectedImage.Titulo} />
                    <button onClick={closeModal}>Cerrar</button>
                </Modal>
            )}
        </div>
    );
}

export default Galeria;
