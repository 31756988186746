// src/Services/encuestaServices.js
import axios from 'axios';
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Define la URL específica para encuestas basada en API_BASE_URL
const API_URL = `${API_BASE_URL}/api/encuestas`;

// Función para enviar la respuesta de la encuesta
export const submitEncuesta = async (data) => {
    try {
        const response = await axios.post(API_URL, data);
        return response.data;
    } catch (error) {
        console.error('Error al enviar la encuesta:', error);
        throw error;
    }
};


// Función para obtener todas las encuestas
export const fetchEncuestas = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener encuestas:', error);
        throw error;
    }
};


// Función para eliminar todas las encuestas
export const resetEncuestas = async () => {
    try {
        const response = await axios.delete(API_URL); // Llamada al endpoint de eliminar todas las encuestas
        return response.data;
    } catch (error) {
        console.error('Error al resetear las encuestas:', error);
        throw error;
    }
};

// Función para obtener estadísticas de las encuestas
export const fetchEncuestaStatistics = async () => {
    try {
        const response = await axios.get(`${API_URL}/statistics`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener las estadísticas de encuestas:', error);
        throw error;
    }
};

// Función para filtrar encuestas por criterios (calificación o fecha)
export const filterEncuestas = async (filters) => {
    try {
        const response = await axios.get(API_URL, {
            params: filters, // Los filtros se envían como parámetros de la URL
        });
        return response.data;
    } catch (error) {
        console.error('Error al filtrar encuestas:', error);
        throw error;
    }
};
