import React, { useState, useEffect } from 'react';
import { fetchServicios, createServicio, updateServicio, deleteServicio } from '../../Services/serviciosServices';
import { fetchCategoriasServicios } from '../../Services/categoriasServiciosServices';
import './ServiciosAdmin.css';

const ServiciosAdmin = () => {
    const [services, setServices] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        description: '',
        price: '',
        category: ''
    });
    const [errors, setErrors] = useState({});
    const MAX_WORDS_TITLE = 10;
    const MAX_WORDS_DESCRIPTION = 30;
    const MAX_CHARACTERS = 200;

    useEffect(() => {
        loadServices();
        loadCategorias();
    }, []);

    const loadServices = async () => {
        try {
            const data = await fetchServicios();
            setServices(data);
        } catch (error) {
            console.error('Error al obtener los servicios:', error);
        }
    };

    const loadCategorias = async () => {
        try {
            const data = await fetchCategoriasServicios(1);
            setCategorias(data);
        } catch (error) {
            console.error('Error al obtener las categorías activas:', error);
        }
    };

    const handleAddService = () => {
        setSelectedService(null);
        setImageFile(null);
        setFormValues({ title: '', description: '', price: '', category: '' });
        setErrors({});
        setIsModalOpen(true);
    };

    const handleEditService = (service) => {
        setSelectedService(service);
        setImageFile(null);
        setFormValues({
            title: service.NombreServicio || '',
            description: service.Descripcion || '',
            price: service.Precio || '',
            category: service.idCategoriaServicio || ''
        });
        setErrors({});
        setIsModalOpen(true);
    };

    const handleDeleteService = async (service) => {
        try {
            await deleteServicio(service.idServicio);
            loadServices();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Error al eliminar servicio:', error);
        }
    };

    const closeModals = () => {
        setIsModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedService(null);
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'title') {
            const words = value.trim().split(/\s+/);
            if (words.length <= MAX_WORDS_TITLE && value.length <= MAX_CHARACTERS) {
                setFormValues({ ...formValues, [name]: value });
            }
        } else if (name === 'description') {
            const words = value.trim().split(/\s+/);
            if (words.length <= MAX_WORDS_DESCRIPTION && value.length <= MAX_CHARACTERS) {
                setFormValues({ ...formValues, [name]: value });
            }
        } else if (name === 'price') {
            const numericValue = value.replace(/[^0-9]/g, '');
            setFormValues({ ...formValues, [name]: numericValue });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }

        setErrors({ ...errors, [name]: '' });
    };

    const validateForm = () => {
        let formErrors = {};

        if (!formValues.title) formErrors.title = 'El título es obligatorio';
        if (!formValues.description) formErrors.description = 'La descripción es obligatoria';
        if (!formValues.price) formErrors.price = 'El precio es obligatorio';
        if (!formValues.category) formErrors.category = 'Debes seleccionar una categoría';

        if (formValues.title.split(/\s+/).length > MAX_WORDS_TITLE) {
            formErrors.title = `El título no puede exceder ${MAX_WORDS_TITLE} palabras`;
        }
        if (formValues.description.split(/\s+/).length > MAX_WORDS_DESCRIPTION) {
            formErrors.description = `La descripción no puede exceder ${MAX_WORDS_DESCRIPTION} palabras`;
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSaveService = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const newService = {
            NombreServicio: formValues.title,
            Descripcion: formValues.description,
            Precio: formValues.price,
            Imagen_Url: selectedService?.Imagen_Url || '',
            idCategoriaServicio: formValues.category
        };

        try {
            if (selectedService) {
                await updateServicio(selectedService.idServicio, newService, imageFile);
            } else {
                await createServicio(newService, imageFile);
            }
            loadServices();
            closeModals();
        } catch (error) {
            console.error('Error al guardar el servicio:', error);
        }
    };

    return (
        <div className="admin-dashboard">
            <header className="admin-header">
                <h1>Servicios</h1>
                <button className="btn-add" onClick={handleAddService}>Agregar Nuevo</button>
            </header>
            <div className="service-list">
                {services.map(service => (
                    <div key={service.idServicio} className="service-card">
                        <img src={service.Imagen_Url} alt={service.NombreServicio} className="service-image" />
                        <h3>{service.NombreServicio}</h3>
                        <p>{service.Descripcion}</p>
                        <p className="service-price">Precio: ${service.Precio}</p>
                        <p className="service-category">Categoría: {service.CategoriasServicios?.NombreCategoriaServicio || 'Sin categoría'}</p>
                        <button className="btn-edit" onClick={() => handleEditService(service)}>Editar</button>
                        <button className="btn-delete" onClick={() => handleDeleteService(service)}>Eliminar</button>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>{selectedService ? 'Editar Servicio' : 'Agregar Servicio'}</h3>
                        <form onSubmit={handleSaveService}>
                            <input type="file" name="image" onChange={handleImageChange} />
                            <input
                                type="text"
                                name="title"
                                placeholder="Título"
                                value={formValues.title}
                                onChange={handleInputChange}
                            />
                            {errors.title && <p className="error-text">{errors.title}</p>}
                            <textarea
                                name="description"
                                placeholder="Descripción"
                                value={formValues.description}
                                onChange={handleInputChange}
                                rows={10}
                            ></textarea>
                            {errors.description && <p className="error-text">{errors.description}</p>}
                            <input
                                type="text"
                                name="price"
                                placeholder="Precio"
                                value={formValues.price}
                                onChange={handleInputChange}
                            />
                            {errors.price && <p className="error-text">{errors.price}</p>}
                            <select
                                name="category"
                                value={formValues.category}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Seleccionar Categoría</option>
                                {categorias.map(categoria => (
                                    <option key={categoria.idCategoriaServicio} value={categoria.idCategoriaServicio}>
                                        {categoria.NombreCategoriaServicio}
                                    </option>
                                ))}
                            </select>
                            {errors.category && <p className="error-text">{errors.category}</p>}
                            <div className="button-group">
                                <button type="submit" className="btn-save">Guardar</button>
                                <button type="button" className="btn-close" onClick={closeModals}>Cerrar</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isDeleteModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Confirmar Eliminación</h3>
                        <p>¿Estás seguro de que deseas eliminar {selectedService?.NombreServicio}?</p>
                        <button className="btn-confirm" onClick={() => handleDeleteService(selectedService)}>Confirmar</button>
                        <button type="button" className="btn-close" onClick={closeModals}>Cancelar</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiciosAdmin;
