// src/Services/categoriasServiciosServices.js
import API_BASE_URL from './apiConfig';

// Obtener todas las categorías de servicios según el estado (activo o inactivo)
export const fetchCategoriasServicios = async (estado) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios?estado=${estado}`);
        if (!response.ok) {
            throw new Error('Error al obtener las categorías de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching service categories:', error);
        throw error;
    }
};

// Crear una nueva categoría de servicios
export const createCategoriaServicio = async (categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al crear la categoría de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating service category:', error);
        throw error;
    }
};

// Desactivar una categoría de servicios (borrado lógico)
export const deactivateCategoriaServicio = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios/${id}/borrado-logico`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al desactivar la categoría de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deactivating service category:', error);
        throw error;
    }
};

// Activar una categoría de servicios inactiva
export const activateCategoriaServicio = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios/${id}/activar`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al activar la categoría de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error activating service category:', error);
        throw error;
    }
};

// Eliminar una categoría de servicios
export const deleteCategoriaServicio = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Error al eliminar la categoría de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting service category:', error);
        throw error;
    }
};

// Actualizar una categoría de servicios por ID
export const updateCategoriaServicio = async (id, categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasServicios/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al actualizar la categoría de servicios');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating service category:', error);
        throw error;
    }
};
