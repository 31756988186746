import React, { useState, useEffect } from 'react';
import { 
    fetchCategorias, 
    createCategoria, 
    deactivateCategoria, 
    activateCategoria 
} from '../../Services/categoriaServices'; // Ajusta la ruta según tu estructura
import './CategoriaGaleriaAdmin.css'; // Importar el CSS

const CategoriaGaleriaAdmin = () => {
    const [estado, setEstado] = useState(1); // Para obtener activas/inactivas
    const [categorias, setCategorias] = useState([]);
    const [categoriaId, setCategoriaId] = useState('');
    const [nombreCategoria, setNombreCategoria] = useState('');
    const [mensaje, setMensaje] = useState('');

    useEffect(() => {
        const loadCategorias = async () => {
            try {
                const data = await fetchCategorias(estado);
                setCategorias(data);
                setMensaje(`Categorías obtenidas con éxito. Total: ${data.length}`);
            } catch (error) {
                setMensaje(`Error al obtener categorías: ${error.message}`);
            }
        };

        loadCategorias();
    }, [estado]);

    const handleCreateCategoria = async () => {
        try {
            const data = await createCategoria({ NombreCategoria: nombreCategoria });
            setMensaje(`Categoría creada con éxito: ${data.NombreCategoria}`);
            setNombreCategoria('');
            const updatedCategorias = await fetchCategorias(estado);
            setCategorias(updatedCategorias);
        } catch (error) {
            setMensaje(`Error al crear categoría: ${error.message}`);
        }
    };

    const handleDeactivateCategoria = async (id) => {
        try {
            const data = await deactivateCategoria(id);
            setMensaje(`Categoría desactivada: ${data.categoria.NombreCategoria}`);
            const updatedCategorias = await fetchCategorias(estado);
            setCategorias(updatedCategorias);
        } catch (error) {
            setMensaje(`Error al desactivar categoría: ${error.message}`);
        }
    };

    const handleActivateCategoria = async (id) => {
        try {
            const data = await activateCategoria(id);
            setMensaje(`Categoría activada: ${data.categoria.NombreCategoria}`);
            const updatedCategorias = await fetchCategorias(estado);
            setCategorias(updatedCategorias);
        } catch (error) {
            setMensaje(`Error al activar categoría: ${error.message}`);
        }
    };

    return (
        <div className="categoria-galeria-admin">
            <h2 className="titulo">Categorías de imagenes</h2>

            <div className="input-section">
                <input 
                    type="text" 
                    placeholder="Nombre de la Categoría" 
                    value={nombreCategoria} 
                    onChange={(e) => setNombreCategoria(e.target.value)} 
                    className="input-categoria"
                />
                <button className="btn btn-crear" onClick={handleCreateCategoria}>Crear Categoría</button>
            </div>

            <div className="filtro-section">
                <label htmlFor="filtro">Mostrar:</label>
                <select id="filtro" value={estado} onChange={(e) => setEstado(Number(e.target.value))} className="select-estado">
                    <option value={1}>Activas</option>
                    <option value={0}>Inactivas</option>
                </select>
            </div>

            {mensaje && <p className="mensaje">{mensaje}</p>}

            {categorias.length > 0 && (
                <ul className="lista-categorias">
                    {categorias.map((categoria) => (
                        <li key={categoria.idCategoriaImg} className="categoria-item">
                            <span>ID: {categoria.idCategoriaImg}, Nombre: {categoria.NombreCategoria}, Estado: {categoria.Estado}</span>
                            <div className="categoria-buttons">
                                <button className="btn btn-seleccionar" onClick={() => setCategoriaId(categoria.idCategoriaImg)}>Seleccionar</button>
                                {categoria.Estado === 1 ? (
                                    <button className="btn btn-desactivar" onClick={() => handleDeactivateCategoria(categoria.idCategoriaImg)}>Desactivar</button>
                                ) : (
                                    <button className="btn btn-activar" onClick={() => handleActivateCategoria(categoria.idCategoriaImg)}>Activar</button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CategoriaGaleriaAdmin;
