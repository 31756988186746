import axios from 'axios';
import API_BASE_URL from './apiConfig'; // URL base definida en tu configuración

const API_URL = `${API_BASE_URL}/api/usuarios`;

// Obtener todos los usuarios
export const fetchUsuarios = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener usuarios:', error);
        throw error;
    }
};

// Obtener un usuario por ID
export const fetchUsuarioById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener usuario:', error);
        throw error;
    }
};

// Crear un nuevo usuario
export const createUsuario = async (usuario) => {
    try {
        const response = await axios.post(API_URL, usuario);
        return response.data;
    } catch (error) {
        console.error('Error al crear usuario:', error);
        throw error;
    }
};

// Actualizar un usuario
export const updateUsuario = async (id, usuario) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, usuario);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar usuario:', error);
        throw error;
    }
};

// Eliminar un usuario
export const deleteUsuario = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar usuario:', error);
        throw error;
    }
};

//Recuperar contraseña
export const fetchUsuarioByEmail = async (email) => {
    try {
        const response = await axios.get(`${API_URL}/email/${email}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener usuario por correo:', error);
        throw error;
    }
};

