import React, { useState, useEffect } from 'react';
import { 
    fetchCategoriasProductos, 
    createCategoriaProducto, 
    deactivateCategoriaProducto, 
    activateCategoriaProducto 
} from '../../Services/categoriasProductosServices'; // Ajusta la ruta según tu estructura
import './CategoriaProductoAdmin.css'; // Importar el CSS

const CategoriaProductosAdmin = () => {
    const [estado, setEstado] = useState(1);  // Estado: 1 para activas, 0 para inactivas
    const [categorias, setCategorias] = useState([]);  // Estado para las categorías
    const [nombreCategoria, setNombreCategoria] = useState('');  // Nombre de la categoría
    const [mensaje, setMensaje] = useState('');  // Mensajes de éxito o error

    // Cargar las categorías automáticamente cuando cambie el estado
    const loadCategorias = async () => {
        try {
            const data = await fetchCategoriasProductos(estado);
            setCategorias(data);
            setMensaje(`Categorías obtenidas con éxito. Total: ${data.length}`);
        } catch (error) {
            setMensaje(`Error al obtener categorías: ${error.message}`);
        }
    };

    useEffect(() => {
        loadCategorias();  // Llamar a la función para cargar las categorías
    }, [estado]);

    // Función para crear una nueva categoría
    const handleCreateCategoria = async () => {
        try {
            const data = await createCategoriaProducto({ NombreCategoriaProducto: nombreCategoria });
            setMensaje(`Categoría creada con éxito: ${data.NombreCategoriaProducto}`);
            setNombreCategoria('');  // Limpiar el campo de entrada
            loadCategorias();  // Actualizamos la lista de categorías
        } catch (error) {
            setMensaje(`Error al crear categoría: ${error.message}`);
        }
    };

    // Función para desactivar una categoría
    const handleDeactivateCategoria = async (id) => {
        try {
            const data = await deactivateCategoriaProducto(id);
            setMensaje(`Categoría desactivada: ${data.NombreCategoriaProducto}`);
            loadCategorias();  // Recargar la lista después de desactivar
        } catch (error) {
            setMensaje(`Error al desactivar categoría: ${error.message}`);
        }
    };

    // Función para activar una categoría
    const handleActivateCategoria = async (id) => {
        try {
            const data = await activateCategoriaProducto(id);
            setMensaje(`Categoría activada: ${data.NombreCategoriaProducto}`);
            loadCategorias();  // Recargar la lista después de activar
        } catch (error) {
            setMensaje(`Error al activar categoría: ${error.message}`);
        }
    };

    return (
        <div className="categoria-productos-admin">
            <h2 className="titulo">Categorías de  de Productos</h2>

            {/* Input para el nombre de la categoría */}
            <div className="input-section">
                <input 
                    type="text" 
                    placeholder="Nombre de la Categoría" 
                    value={nombreCategoria} 
                    onChange={(e) => setNombreCategoria(e.target.value)} 
                    className="input-categoria"
                />
                <button className="btn btn-crear" onClick={handleCreateCategoria}>Crear Categoría</button>
            </div>

            {/* Selector de estado para activas/inactivas */}
            <div className="filtro-section">
                <label htmlFor="filtro">Mostrar:</label>
                <select 
                    id="filtro" 
                    value={estado} 
                    onChange={(e) => setEstado(Number(e.target.value))} 
                    className="select-estado"
                >
                    <option value={1}>Activas</option>
                    <option value={0}>Inactivas</option>
                </select>
            </div>

            {/* Mostrar mensaje de éxito o error */}
            {mensaje && <p className="mensaje">{mensaje}</p>}

            {/* Mostrar la lista de categorías obtenidas */}
            {categorias.length > 0 && (
                <ul className="lista-categorias">
                    {categorias.map((categoria) => (
                        <li key={categoria.idCategoriaProducto} className="categoria-item">
                            <span>ID: {categoria.idCategoriaProducto}, Nombre: {categoria.NombreCategoriaProducto}, Estado: {categoria.Estado}</span>
                            <div className="categoria-buttons">
                                {categoria.Estado === 1 ? (
                                    <button className="btn btn-desactivar" onClick={() => handleDeactivateCategoria(categoria.idCategoriaProducto)}>Desactivar</button>
                                ) : (
                                    <button className="btn btn-activar" onClick={() => handleActivateCategoria(categoria.idCategoriaProducto)}>Activar</button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CategoriaProductosAdmin;
