import React, { useState, useEffect, useRef } from 'react';
import './Productos.css';
import { fetchProducts } from '../../../Services/productosServices';
import { fetchCategoriasProductos } from '../../../Services/categoriasProductosServices'; // Importa el servicio

const Productos = () => {
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const carruselRef = useRef(null);

    // Cargar productos y categorías
    useEffect(() => {
        const obtenerDatos = async () => {
            try {
                // Cargar productos y categorías
                const productosData = await fetchProducts();
                const categoriasData = await fetchCategoriasProductos(1); // Estado activo
                setProductos(productosData);
                setCategorias(categoriasData);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        obtenerDatos();
    }, []);

    // Función para obtener el nombre de la categoría
    const obtenerNombreCategoria = (idCategoriaProducto) => {
        const categoria = categorias.find(cat => cat.idCategoriaProducto === idCategoriaProducto);
        return categoria ? categoria.NombreCategoriaProducto : 'Sin categoría';
    };

    const scrollLeft = () => {
        carruselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        carruselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    return (
        <div className="productos">
            <h2 className="productos-title">Productos</h2>
            <p className="productos-subtitle">Tenemos una alta gama de productos de calidad</p>
            <div className="carrusel-container">
                <button className="carrusel-button left" onClick={scrollLeft}>‹</button>
                <div className="productos-carrusel" ref={carruselRef}>
                    {productos.map((producto) => (
                        <div className="producto-card" key={producto.idProducto}>
                            <img src={producto.Imagen_Url} alt={producto.NombreProducto} className="producto-imagen" />
                            <div className="producto-content">
                                {/* Mostrar categoría del producto */}
                                <p className="producto-categoria">
                                    {obtenerNombreCategoria(producto.idCategoriaProducto)}
                                </p>
                                <h3 className="producto-title">{producto.NombreProducto}</h3>
                                <p className="producto-descripcion">{producto.Descripcion.substring(0, 200)}</p>
                                <p className="producto-precio">Precio: ${producto.Precio}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carrusel-button right" onClick={scrollRight}>›</button>
            </div>
        </div>
    );
};

export default Productos;
