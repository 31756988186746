import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Header.css';
import logo from '../../../assets/imagenes/logolmao.png';
import EscribenosModal from '../Contactos/EscribenosModal'; // Ajusta la ruta si es necesario

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link to="inicio" smooth={true} duration={500} onClick={toggleMenu}>Inicio</Link></li>
                        <li><Link to="servicios" smooth={true} duration={500} onClick={toggleMenu}>Servicios</Link></li>
                        <li><Link to="productos" smooth={true} duration={500} onClick={toggleMenu}>Productos</Link></li>
                        <li><Link to="contacto" smooth={true} duration={500} onClick={toggleMenu}>Contacto</Link></li>
                        <li><Link to="nosotros" smooth={true} duration={500} onClick={toggleMenu}>Nosotros</Link></li>
                    </ul>
                    <Link className="btn-gold" onClick={() => setIsModalOpen(true)}> Escríbenos </Link>
                </nav>
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </button>
            </div>
            {isModalOpen && (
                <EscribenosModal 
                    isOpen={isModalOpen} 
                    closeModal={() => setIsModalOpen(false)} 
                />
            )}
        </header>
    );
};

export default Header;
