import React, { useState, useEffect } from 'react';
import { fetchProducts, createProduct, updateProduct, deleteProduct } from '../../Services/productosServices';
import { fetchCategoriasProductos } from '../../Services/categoriasProductosServices';
import './ProductosAdmin.css';

const ProductosAdmin = () => {
    const [products, setProducts] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [formValues, setFormValues] = useState({
        title: '',
        description: '',
        price: '',
        category: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        loadProducts();
        loadCategorias();
    }, []);

    const loadProducts = async () => {
        try {
            const data = await fetchProducts();
            setProducts(data);
        } catch (error) {
            console.error('Error al obtener los productos:', error);
        }
    };

    const loadCategorias = async () => {
        try {
            const data = await fetchCategoriasProductos(1);
            setCategorias(data);
        } catch (error) {
            console.error('Error al obtener las categorías:', error);
        }
    };

    const handleAddProduct = () => {
        setSelectedProduct(null);
        setImageFile(null);
        setFormValues({ title: '', description: '', price: '', category: '' });
        setErrors({});
        setIsModalOpen(true);
    };

    const handleEditProduct = (product) => {
        setSelectedProduct(product);
        setImageFile(null);
        setFormValues({
            title: product.NombreProducto || '',
            description: product.Descripcion || '',
            price: product.Precio || '',
            category: product.idCategoriaProducto || ''
        });
        setErrors({});
        setIsModalOpen(true);
    };

    const handleDeleteProduct = async (product) => {
        try {
            await deleteProduct(product.idProductos);
            loadProducts();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Error al eliminar producto:', error);
        }
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };    

    const closeModals = () => {
        setIsModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedProduct(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSaveProduct = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const newProduct = {
            NombreProducto: formValues.title,
            Descripcion: formValues.description,
            Precio: formValues.price,
            Imagen_Url: selectedProduct?.Imagen_Url || '',
            idCategoriaProducto: formValues.category
        };

        try {
            if (selectedProduct) {
                await updateProduct(selectedProduct.idProductos, newProduct, imageFile);
            } else {
                await createProduct(newProduct, imageFile);
            }
            loadProducts();
            closeModals();
        } catch (error) {
            console.error('Error al guardar el producto:', error);
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formValues.title) formErrors.title = 'El título es obligatorio';
        if (!formValues.description) formErrors.description = 'La descripción es obligatoria';
        if (!formValues.price) formErrors.price = 'El precio es obligatorio';
        if (!formValues.category) formErrors.category = 'Debes seleccionar una categoría';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    return (
        <div className="admin-dashboard">
            <header className="admin-header">
                <h1>Productos</h1>
                <button className="btn-add" onClick={handleAddProduct}>Agregar Nuevo</button>
            </header>
            <div className="product-list">
                {products.map(product => (
                    <div key={product.idProductos} className="product-card">
                        <img src={product.Imagen_Url} alt={product.NombreProducto} className="product-image" />
                        <h3 className="prodcttitle">{product.NombreProducto}</h3>
                        <p>{product.Descripcion}</p>
                        <p className="product-price">Precio: ${product.Precio}</p>
                        <p className="product-category">
                            Categoría: {product.CategoriasProductos?.NombreCategoriaProducto || 'Sin categoría'}
                        </p>
                        <button className="btn-edit" onClick={() => handleEditProduct(product)}>Editar</button>
                        <button className="btn-delete" onClick={() => handleDeleteProduct(product)}>Eliminar</button>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>{selectedProduct ? 'Editar Producto' : 'Agregar Producto'}</h3>
                        <form onSubmit={handleSaveProduct}>
                            <input type="file" name="image" onChange={handleImageChange} />
                            
                            <input
                                type="text"
                                name="title"
                                placeholder="Título"
                                value={formValues.title}
                                onChange={handleInputChange}
                            />
                            
                            {errors.title && <p className="error-text">{errors.title}</p>}
                            <textarea
                                name="description"
                                placeholder="Descripción"
                                value={formValues.description}
                                onChange={handleInputChange}
                                rows={10}
                            ></textarea>
                            {errors.description && <p className="error-text">{errors.description}</p>}
                            <input
                                type="text"
                                name="price"
                                placeholder="Precio"
                                value={formValues.price}
                                onChange={handleInputChange}
                            />
                            {errors.price && <p className="error-text">{errors.price}</p>}
                            <select
                                name="category"
                                value={formValues.category}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Seleccionar Categoría</option>
                                {categorias.map(categoria => (
                                    <option key={categoria.idCategoriaProducto} value={categoria.idCategoriaProducto}>
                                        {categoria.NombreCategoriaProducto}
                                    </option>
                                ))}
                            </select>
                            {errors.category && <p className="error-text">{errors.category}</p>}
                            <div className="button-group">
                                <button type="submit" className="btn-save">Guardar</button>
                                <button type="button" className="btn-close" onClick={closeModals}>Cerrar</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductosAdmin;
