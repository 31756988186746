// Importa las funciones necesarias del SDK de Firebase
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";  // Importar Firebase Storage

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB5mmIvhACYIgbgdr5HnDQo7toBud8GD48",
  authDomain: "peluqueriamary-f9669.firebaseapp.com",
  projectId: "peluqueriamary-f9669",
  storageBucket: "peluqueriamary-f9669.appspot.com",
  messagingSenderId: "921169865020",
  appId: "1:921169865020:web:9a54384ff57085d8ab5639",
  measurementId: "G-G2KXH26TSZ"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa el almacenamiento de Firebase (Firebase Storage)
const storage = getStorage(app);

export { storage };
