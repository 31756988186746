import React from 'react';
import Header from '../LandingPage/Header/Header';
import Hero from '../LandingPage/Hero/Hero';
import Servicios from '../LandingPage/Servicios/Servicios';
import Galeria from '../LandingPage/Galeria/Galeria';
import Productos from '../LandingPage/Productos/Productos';
import Nosotros from '../LandingPage/Nosotros/Nosotros';
import Ubicacion from '../LandingPage/Ubicacion/Ubicacion';
import Contacto from '../LandingPage/Contactos/Contacto';
import Tips from '../LandingPage/Tips/Tips';
import EncuestaButton from '../LandingPage/Encuesta/EncuestaButton';

function LandingPage() {
    return (
        <div className="LandingPage">
            <Header />
            <section id="inicio">
                <Hero />
            </section>
            <section id="servicios">
                <Servicios />
            </section>
            <section id="galeria">
                <Galeria />
            </section>
            <section id="productos">
                <Productos />
            </section>
            <section id="tips">
                <Tips />
            </section>
            <section id="nosotros">
                <Nosotros />
            </section>
            <section id="ubicacion">
                <Ubicacion />
            </section>
            <section id="contacto">
                <Contacto />
            </section>
         <EncuestaButton />
        </div>
        
    );
}

export default LandingPage;