// src/Services/authServices.js
import axios from 'axios';
import API_BASE_URL from './apiConfig';  // Importa la URL base desde el archivo de configuración

const API_URL = `${API_BASE_URL}/api/usuarios/login`;  // Endpoint de autenticación

// Servicio de autenticación para el login
export const login = async (email, password) => {
    try {
        const response = await axios.post(API_URL, { email, contrasena: password });
        
        // Almacenar el token recibido en el localStorage
        const token = response.data.token;  // Suponemos que el backend devuelve el token en response.data.token
        if (token) {
            localStorage.setItem('token', token);
        }

        return { isAuthenticated: true };  // Retorna autenticado si se guarda el token
    } catch (error) {
        const errorMessage = error.response?.data?.error || 'Error en la autenticación';
        console.error(errorMessage);
        return { isAuthenticated: false, message: errorMessage };
    }
};

// Servicio para obtener el token desde el localStorage
export const getToken = () => {
    return localStorage.getItem('token');
};

// Servicio para cerrar sesión
export const logout = () => {
    localStorage.removeItem('token');  // Elimina el token al cerrar sesión
};
