import axios from 'axios';
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Usa la URL base del backend
const API_URL = `${API_BASE_URL}/api/ubicacion`;

// Función para obtener los datos de "Ubicación"
export const fetchUbicacionData = async () => {
  try {
    const response = await axios.get(API_URL);
    console.log('Datos de Ubicación obtenidos:', response.data); // Verifica los datos recibidos
    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos de Ubicación:', error);
    throw error;
  }
};

// Función para actualizar un registro existente de "Ubicación"
export const updateUbicacionData = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/${data.idUbicacion}`, data);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar los datos de Ubicación:', error);
    throw error;
  }
};
