// src/Services/productosServices.js
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebaseConfig'; // Asegúrate de que esta ruta apunte correctamente a tu configuración de Firebase
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Define la URL de productos usando la base
const API_URL = `${API_BASE_URL}/api/productos`;

// Obtener todos los productos
export const fetchProducts = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener productos:', error);
        throw error;
    }
};

// Subir imagen a Firebase Storage
const uploadImageToFirebase = async (imageFile) => {
    if (!imageFile) return null;

    const imageRef = ref(storage, `productos/${imageFile.name}`);
    const snapshot = await uploadBytes(imageRef, imageFile);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
};

// Crear un nuevo producto con imagen
export const createProduct = async (product, imageFile) => {
    try {
        const imageUrl = await uploadImageToFirebase(imageFile);
        const productWithImage = { ...product, Imagen_Url: imageUrl };
        const response = await axios.post(API_URL, productWithImage);
        return response.data;
    } catch (error) {
        console.error('Error al crear producto:', error);
        throw error;
    }
};

// Actualizar un producto existente con imagen
export const updateProduct = async (id, product, imageFile) => {
    try {
        let imageUrl = product.Imagen_Url;

        if (imageFile) {
            imageUrl = await uploadImageToFirebase(imageFile);
        }

        const productWithImage = { ...product, Imagen_Url: imageUrl };
        const response = await axios.put(`${API_URL}/${id}`, productWithImage);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar producto:', error);
        throw error;
    }
};

// Eliminar un producto
export const deleteProduct = async (id) => {
    try {
        await axios.delete(`${API_URL}/${id}`);
    } catch (error) {
        console.error('Error al eliminar producto:', error);
        throw error;
    }
};
