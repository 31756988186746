import React, { useState, useEffect } from 'react';
import { 
    fetchCategoriasServicios, 
    createCategoriaServicio, 
    deactivateCategoriaServicio, 
    activateCategoriaServicio 
} from '../../Services/categoriasServiciosServices'; // Ajusta la ruta según tu estructura
import './CategoriaServiciosAdmin.css'; // Importar el CSS

const CategoriaServiciosAdmin = () => {
    const [estado, setEstado] = useState(1); // Para obtener activas/inactivas
    const [categorias, setCategorias] = useState([]);
    const [nombreCategoria, setNombreCategoria] = useState('');
    const [mensaje, setMensaje] = useState('');

    // Obtener categorías automáticamente cuando cambia el estado (activas/inactivas)
    useEffect(() => {
        const handleFetchCategorias = async () => {
            try {
                const data = await fetchCategoriasServicios(estado);
                setCategorias(data);
                setMensaje(`Categorías obtenidas con éxito. Total: ${data.length}`);
            } catch (error) {
                setMensaje(`Error al obtener categorías: ${error.message}`);
            }
        };
        handleFetchCategorias();
    }, [estado]);

    // Crear nueva categoría
    const handleCreateCategoria = async () => {
        try {
            const data = await createCategoriaServicio({ NombreCategoriaServicio: nombreCategoria });
            setMensaje(`Categoría creada con éxito: ${data.NombreCategoriaServicio}`);
            // Refresca la lista de categorías después de crear una nueva
            const updatedCategorias = await fetchCategoriasServicios(estado);
            setCategorias(updatedCategorias);
            setNombreCategoria(''); // Limpiar el input
        } catch (error) {
            setMensaje(`Error al crear categoría: ${error.message}`);
        }
    };

    // Desactivar categoría (borrado lógico)
    const handleDeactivateCategoria = async (id) => {
        try {
            await deactivateCategoriaServicio(id);
            setMensaje('Categoría desactivada con éxito');
            const updatedCategorias = await fetchCategoriasServicios(estado);
            setCategorias(updatedCategorias);
        } catch (error) {
            setMensaje(`Error al desactivar categoría: ${error.message}`);
        }
    };

    // Activar categoría inactiva
    const handleActivateCategoria = async (id) => {
        try {
            await activateCategoriaServicio(id);
            setMensaje('Categoría activada con éxito');
            const updatedCategorias = await fetchCategoriasServicios(estado);
            setCategorias(updatedCategorias);
        } catch (error) {
            setMensaje(`Error al activar categoría: ${error.message}`);
        }
    };

    return (
        <div className="categoria-servicios-admin">
            <h2 className="titulo">Categorías de Servicios</h2>

            {/* Input para el nombre de la categoría */}
            <div className="input-section">
                <input 
                    type="text" 
                    placeholder="Nombre de la Categoría" 
                    value={nombreCategoria} 
                    onChange={(e) => setNombreCategoria(e.target.value)} 
                    className="input-categoria"
                />
                <button className="btn btn-crear" onClick={handleCreateCategoria}>Crear Categoría</button>
            </div>

            {/* Selector de estado para activas/inactivas */}
            <div className="filtro-section">
                <label htmlFor="filtro">Mostrar:</label>
                <select 
                    id="filtro" 
                    value={estado} 
                    onChange={(e) => setEstado(Number(e.target.value))} 
                    className="select-estado"
                >
                    <option value={1}>Activas</option>
                    <option value={0}>Inactivas</option>
                </select>
            </div>

            {/* Mostrar mensaje de éxito o error */}
            {mensaje && <p className="mensaje">{mensaje}</p>}

            {/* Mostrar la lista de categorías obtenidas */}
            {categorias.length > 0 && (
                <ul className="lista-categorias">
                    {categorias.map((categoria) => (
                        <li key={categoria.idCategoriaServicio} className="categoria-item">
                            <span>ID: {categoria.idCategoriaServicio}, Nombre: {categoria.NombreCategoriaServicio}, Estado: {categoria.Estado}</span>
                            <div className="categoria-buttons">
                                {categoria.Estado === 1 ? (
                                    <button className="btn btn-desactivar" onClick={() => handleDeactivateCategoria(categoria.idCategoriaServicio)}>Desactivar</button>
                                ) : (
                                    <button className="btn btn-activar" onClick={() => handleActivateCategoria(categoria.idCategoriaServicio)}>Activar</button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CategoriaServiciosAdmin;
