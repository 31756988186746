import React, { useState, useEffect } from 'react';
import EncuestaModal from './EncuestaModal';
import './EncuestaButton.css';
import surveyIcon from '../../../assets/imagenes/IconoEncuesta2.png'; // Ruta al ícono

const EncuestaButton = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Detecta el tamaño de la pantalla al cargar y cuando se redimensiona
        handleResize();
        window.addEventListener('resize', handleResize);

        // Limpia el event listener cuando el componente se desmonta
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <>
            <button className="encuesta-button" onClick={openModal}>
                <img src={surveyIcon} alt="Encuesta Icon" className="survey-icon" />
                {!isMobile && <span>Que te parece nuestro servicio?!</span>}
            </button>
            <EncuestaModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default EncuestaButton;
