import React, { useState, useEffect } from 'react';
import { fetchGaleriaImagenes, createGaleriaImagen, deleteGaleriaImagen } from '../../Services/galeriaServices';
import { fetchCategorias } from '../../Services/categoriaServices';
import { storage } from '../../config/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './GaleriaAdmin.css';

// Constante para el límite de caracteres en el título
const MAX_TITLE_CHARACTERS = 50;

const GalleryForm = () => {
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const loadCategorias = async () => {
            try {
                const data = await fetchCategorias(1); // Solo obtenemos las categorías activas
                setCategories(data);
            } catch (error) {
                console.error('Error al obtener las categorías activas:', error);
            }
        };
        loadCategorias();
    }, []);

    useEffect(() => {
        const loadGalleryImages = async () => {
            try {
                const data = await fetchGaleriaImagenes();
                setGalleryImages(data);
            } catch (error) {
                console.error('Error fetching gallery images:', error);
            }
        };
        loadGalleryImages();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleTitleChange = (e) => {
        const value = e.target.value;
        if (value.length <= MAX_TITLE_CHARACTERS) {
            setTitle(value);
            setErrors({ ...errors, title: '' }); // Limpiar el error si existe
        } else {
            setErrors({ ...errors, title: `Máximo ${MAX_TITLE_CHARACTERS} caracteres` });
        }
    };

    const uploadImageToFirebase = async (file) => {
        if (!file) return null;
        const imageRef = ref(storage, `galeria/${file.name}`);
        try {
            const snapshot = await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading image to Firebase:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!image || !title || !category) {
            alert('Por favor, completa todos los campos');
            return;
        }

        const imageUrl = await uploadImageToFirebase(image);
        if (!imageUrl) {
            alert('Error al subir la imagen');
            return;
        }

        const newImage = {
            Titulo: title,
            Imagen_Url: imageUrl,
            idCategoriaImg: categories.find(cat => cat.NombreCategoria === category)?.idCategoriaImg,
        };

        try {
            const createdImage = await createGaleriaImagen(newImage);
            setGalleryImages([...galleryImages, createdImage]);
            setImage(null);
            setTitle('');
            setCategory('');
            setImagePreview(null);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error creating gallery image:', error);
        }
    };

    const handleDeleteImage = async (idGImagen) => {
        try {
            await deleteGaleriaImagen(idGImagen);
            setGalleryImages(galleryImages.filter(img => img.idGImagen !== idGImagen));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="gallery-form-container">
            <div className="header-container">
                <h2>Administrar Galería</h2>
                <button className="btn-add" onClick={handleOpenModal}>Agregar Nueva Imagen</button>
            </div>
            
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Agregar Nueva Imagen</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="title">Título:</label>
                                <input
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder={`Máximo ${MAX_TITLE_CHARACTERS} caracteres`}
                                />
                                {errors.title && <p className="error-text">{errors.title}</p>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="image">Imagen:</label>
                                <input
                                    type="file"
                                    id="image"
                                    onChange={handleImageChange}
                                />
                                {imagePreview && (
                                    <div>
                                        <img src={imagePreview} alt="Vista previa" style={{ maxWidth: '100%', marginTop: '10px' }} />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="category">Categoría:</label>
                                <select
                                    id="category"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="">Selecciona una categoría</option>
                                    {categories.map(cat => (
                                        <option key={cat.idCategoriaImg} value={cat.NombreCategoria}>
                                            {cat.NombreCategoria}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button type="submit" className="btn-submit">Guardar</button>
                            <button type="button" className="btn-close" onClick={handleCloseModal}>Cerrar</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="gallery-image-list">
                {galleryImages.map(img => (
                    <div key={img.idGImagen} className="gallery-image-card">
                        <img src={img.Imagen_Url} alt={img.Titulo} className="gallery-image" />
                        <p className="title">{img.Titulo}</p>
                        <p className="category">{categories.find(cat => cat.idCategoriaImg === img.idCategoriaImg)?.NombreCategoria}</p>
                        <button onClick={() => handleDeleteImage(img.idGImagen)}>Eliminar</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GalleryForm;
