import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, logout } from '../../Services/authServices'; // Importamos las funciones necesarias
import ServiciosAdmin from '../Admin/ServiciosAdmin';
import ProductosAdmin from '../Admin/ProductosAdmin';
import GaleriaAdmin from '../Admin/GaleriaAdmin';
import TipsAdmin from '../Admin/TipsAdmin';
import ContactosAdmin from '../Admin/ContactosAdmin';
import EncuestasAdmin from '../Admin/EncuestasAdmin'; 
import ApartadoCategorias from '../Admin/ApartadoCategorias'; 
import NosotrosAdmin from '../Admin/NosotrosAdmin'; 
import UbicacionAdmin from '../Admin/UbicacionAdmin'; 
import UsuariosAdmin from '../Admin/UsuariosAdmin';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const [activeComponent, setActiveComponent] = useState('Servicios');
    const navigate = useNavigate();

    // Verificamos si el usuario está autenticado usando el token
    useEffect(() => {
        const token = getToken();
        if (!token) {
            navigate('/login');  // Si no hay token, redirigimos al login
        }
    }, [navigate]);

    // Función para cerrar sesión
    const handleLogout = () => {
        logout();  // Llamamos a la función de logout que elimina el token
        navigate('/login');  // Redirigimos al login
    };

    // Renderizamos el componente correspondiente según el menú
    const renderComponent = () => {
        switch (activeComponent) {
            case 'Servicios':
                return <ServiciosAdmin />;
            case 'Productos':
                return <ProductosAdmin />;
            case 'Galeria':
                return <GaleriaAdmin />;
            case 'Tips':
                return <TipsAdmin />;
            case 'Contactos':
                return <ContactosAdmin />;
            case 'Encuestas':
                return <EncuestasAdmin />;
            case 'Categorias':
                return <ApartadoCategorias />; 
            case 'Nosotros':
                return <NosotrosAdmin />;  
            case 'Ubicacion':
                return <UbicacionAdmin />;
            case 'Usuarios':
                return <UsuariosAdmin />;    
            default:
                return <ServiciosAdmin />;
        }
    };

    return (
        <div className="admin-dashboard">
            <header className="admin-header">
                <h1>Admin Dashboard</h1>
                <button onClick={handleLogout} className="logout-button">Cerrar Sesión</button> {/* Botón de logout */}
            </header>
            <nav className="admin-nav">
                <ul>
                    <li><a href="#servicios-admin" onClick={() => setActiveComponent('Servicios')}>Servicios</a></li>
                    <li><a href="#productos-admin" onClick={() => setActiveComponent('Productos')}>Productos</a></li>
                    <li><a href="#galeria-admin" onClick={() => setActiveComponent('Galeria')}>Galería</a></li>
                    <li><a href="#tips-admin" onClick={() => setActiveComponent('Tips')}>Tips</a></li>
                    <li><a href="#contactos-admin" onClick={() => setActiveComponent('Contactos')}>Contactos</a></li>
                    <li><a href="#encuestas-admin" onClick={() => setActiveComponent('Encuestas')}>Encuestas</a></li>
                    <li><a href="#categorias-admin" onClick={() => setActiveComponent('Categorias')}>Categorías</a></li> 
                    <li><a href="#nosotros-admin" onClick={() => setActiveComponent('Nosotros')}>Nosotros</a></li> 
                    <li><a href="#ubicacion-admin" onClick={() => setActiveComponent('Ubicacion')}>Ubicación</a></li>
                    <li><a href="#usuarios-admin" onClick={() => setActiveComponent('Usuarios')}>Usuarios</a></li>  
                </ul>
            </nav>
            <main className="admin-content">
                {renderComponent()}
            </main>
        </div>
    );
};

export default AdminDashboard;
