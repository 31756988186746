import React, { useState, useEffect } from 'react';
import { fetchContactos, updateContacto } from '../../Services/contactosServices'; 
import './ContactoAdmin.css';

const ContactAdmin = () => {
    const [contactos, setContactos] = useState([]);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [socialLinks, setSocialLinks] = useState({
        facebook: { id: null, enlace: '' },
        instagram: { id: null, enlace: '' },
        whatsapp: { id: null, enlace: '' },
        tiktok: { id: null, enlace: '' }
    });

    useEffect(() => {
        const loadContactos = async () => {
            try {
                const data = await fetchContactos();
                console.log("Datos obtenidos:", data); // Verifica los datos obtenidos en consola
                
                const links = {
                    facebook: data.find(c => c.NombreContacto === 'Facebook') || { id: null, enlace: '' },
                    instagram: data.find(c => c.NombreContacto === 'Instagram') || { id: null, enlace: '' },
                    whatsapp: data.find(c => c.NombreContacto === 'Whatsapp') || { id: null, enlace: '' },
                    tiktok: data.find(c => c.NombreContacto === 'TikTok') || { id: null, enlace: '' },
                };
                
                setSocialLinks({
                    facebook: { id: links.facebook.idContactos, enlace: links.facebook.Enlace },
                    instagram: { id: links.instagram.idContactos, enlace: links.instagram.Enlace },
                    whatsapp: { id: links.whatsapp.idContactos, enlace: links.whatsapp.Enlace },
                    tiktok: { id: links.tiktok.idContactos, enlace: links.tiktok.Enlace }
                });
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };
        loadContactos();
    }, []);
    

    const handleChange = (e, key) => {
        const { value } = e.target;
        setSocialLinks({
            ...socialLinks,
            [key]: { ...socialLinks[key], enlace: value }
        });
    };

    const handleEdit = (key) => {
        setCurrentEdit(key);
    };

    const handleSave = async (key) => {
        const contacto = socialLinks[key];
        try {
            await updateContacto(contacto.id, { Enlace: contacto.enlace });
            setCurrentEdit(null);
        } catch (error) {
            console.error('Error updating contact:', error);
        }
    };

    return (
        <div className="contact-form-container">
            <div className="header-container">
                <h2>Administrar Redes Sociales</h2>
            </div>
            <form className="contact-form">
                <div className="social-cards-container">
                    {Object.keys(socialLinks).map(key => (
                        <div key={key} className="form-group">
                            <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    id={key}
                                    value={socialLinks[key].enlace}
                                    onChange={(e) => handleChange(e, key)}
                                    disabled={currentEdit !== key}
                                />
                                {socialLinks[key].enlace && (
                                    <>
                                        {currentEdit === key ? (
                                            <button type="button" className="btn-submit" onClick={() => handleSave(key)}>Guardar</button>
                                        ) : (
                                            <button type="button" className="btn-modify" onClick={() => handleEdit(key)}>Modificar</button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </form>
        </div>
    );
};

export default ContactAdmin;
