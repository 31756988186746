import React, { useState, useEffect } from 'react';
import './Nosotros.css';
import { fetchNosotrosData } from '../../../Services/nosotrosServices';  // Asegúrate de que el servicio está correctamente configurado

const Nosotros = () => {
    const [nosotrosData, setNosotrosData] = useState(null);  // Estado para almacenar los datos de un solo registro de Nosotros

    // Obtener los datos del especialista al montar el componente
    useEffect(() => {
        const obtenerNosotros = async () => {
            try {
                const data = await fetchNosotrosData();  // Obtener datos desde la API
                // Verificar si los datos son un array y obtener el primer registro, o usar el objeto directamente
                const especialista = Array.isArray(data) ? data[0] : data;
                setNosotrosData(especialista);  // Guardar los datos del especialista en el estado
            } catch (error) {
                console.error('Error al obtener los datos de Nosotros:', error);
            }
        };

        obtenerNosotros();
    }, []);

    // Mostrar mensaje de carga mientras se obtienen los datos
    if (!nosotrosData) {
        return <p>Cargando información del especialista...</p>;
    }

    return (
        <div className="nosotros-container">
            <h2 className="nosotros-title">Conoce a nuestra especialista</h2>
            <div className="especialista">
                <img
                    src={nosotrosData.imagenUrl || '/path-to-default-image.png'}  // Mostrar imagen del especialista o una predeterminada
                    alt="Imagen del Especialista"
                    className="especialista-imagen"
                />
                <div className="especialista-info">
                    <h3 className="especialista-titulo">{nosotrosData.Titulo || 'Título del Especialista'}</h3>  {/* Título del especialista */}
                    <h2 className="especialista-nombre">{nosotrosData.Nombre || 'Nombre del Especialista'}</h2>  {/* Nombre del especialista */}
                    <p className="especialista-descripcion">{nosotrosData.Descripcion || 'Descripción del especialista.'}</p>  {/* Descripción */}
                </div>
            </div>
        </div>
    );
};

export default Nosotros;
