import React, { useState, useEffect } from 'react';
import { fetchTips, createTip, updateTip, deleteTip } from '../../Services/tipsServices';
import { storage } from '../../config/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './TipsAdmin.css';

const MAX_TITLE_CHARACTERS = 50;
const MAX_DESCRIPTION_CHARACTERS = 150;
const MAX_MOTIVATION_CHARACTERS = 80;

const TipsAdmin = () => {
    const [tips, setTips] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTip, setSelectedTip] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const loadTips = async () => {
            try {
                const data = await fetchTips();
                setTips(data);
            } catch (error) {
                console.error('Error fetching tips:', error);
            }
        };
        loadTips();
    }, []);

    const handleAddTip = () => {
        setSelectedTip(null);
        setErrors({});
        setIsModalOpen(true);
    };

    const handleEditTip = (tip) => {
        setSelectedTip(tip);
        setErrors({});
        setIsModalOpen(true);
    };

    const handleDeleteTip = async (id) => {
        try {
            await deleteTip(id);
            setTips(tips.filter(tip => tip.idTips !== id));
        } catch (error) {
            console.error('Error deleting tip:', error);
        }
    };

    const closeModals = () => {
        setIsModalOpen(false);
        setSelectedTip(null);
    };

    const uploadImageToFirebase = async (file) => {
        if (!file) return null;
        const imageRef = ref(storage, `images/${file.name}`);
        try {
            const snapshot = await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            return downloadURL;
        } catch (error) {
            console.error("Error subiendo la imagen:", error);
            return null;
        }
    };

    const validateForm = (title, description, motivation) => {
        const newErrors = {};

        if (!title) newErrors.title = 'El título es obligatorio';
        else if (title.length > MAX_TITLE_CHARACTERS) newErrors.title = `Máximo ${MAX_TITLE_CHARACTERS} caracteres`;

        if (!description) newErrors.description = 'La descripción es obligatoria';
        else if (description.length > MAX_DESCRIPTION_CHARACTERS) newErrors.description = `Máximo ${MAX_DESCRIPTION_CHARACTERS} caracteres`;

        if (!motivation) newErrors.motivation = 'La frase de motivación es obligatoria';
        else if (motivation.length > MAX_MOTIVATION_CHARACTERS) newErrors.motivation = `Máximo ${MAX_MOTIVATION_CHARACTERS} caracteres`;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveTip = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const title = formData.get('title');
        const description = formData.get('description');
        const motivation = formData.get('motivation');

        if (!validateForm(title, description, motivation)) return;

        const newTip = {
            Titulo: title,
            Descripcion: description,
            Frase_D_Dia: motivation,
            Imagen_Url: ''
        };

        const imageFile = formData.get('image');
        if (imageFile && imageFile.size > 0) {
            const imageUrl = await uploadImageToFirebase(imageFile);
            if (imageUrl) newTip.Imagen_Url = imageUrl;
        }

        try {
            if (selectedTip) {
                const updatedTip = await updateTip(selectedTip.idTips, newTip);
                setTips(tips.map(tip => tip.idTips === updatedTip.idTips ? updatedTip : tip));
            } else {
                const createdTip = await createTip(newTip);
                setTips([...tips, createdTip]);
            }
            closeModals();
        } catch (error) {
            console.error('Error saving tip:', error);
        }
    };

    return (
        <div className="tip-admin-container">
            <div className="admin-header">
                <h1>Administrar Tips</h1>
                <button className="tip-btn-add" onClick={handleAddTip}>Agregar Nuevo</button>
            </div>
            <div className="tip-list">
                {tips.map(tip => (
                    <div key={tip.idTips} className="tip-card">
                        <img src={tip.Imagen_Url} alt={tip.Titulo} className="tip-image" />
                        <h3 className="tip-title">{tip.Titulo}</h3>
                        <p className="tip-description">{tip.Descripcion}</p>
                        <p className="tip-motivation">Motivación: {tip.Frase_D_Dia}</p>
                        <button className="tip-btn-edit" onClick={() => handleEditTip(tip)}>Editar</button>
                        <button className="tip-btn-delete" onClick={() => handleDeleteTip(tip.idTips)}>Eliminar</button>
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="tip-modal-overlay">
                    <div className="tip-modal">
                        <h3>{selectedTip ? 'Editar Tip' : 'Agregar Tip'}</h3>
                        <form onSubmit={handleSaveTip}>
                            <input type="file" name="image" />
                            <input
                                type="text"
                                name="title"
                                placeholder="Título"
                                defaultValue={selectedTip?.Titulo || ''}
                                maxLength={MAX_TITLE_CHARACTERS}
                            />
                            {errors.title && <p className="tip-error-text">{errors.title}</p>}

                            <textarea
                                name="description"
                                placeholder="Descripción"
                                defaultValue={selectedTip?.Descripcion || ''}
                                maxLength={MAX_DESCRIPTION_CHARACTERS}
                                rows={15}
                            ></textarea>
                            {errors.description && <p className="tip-error-text">{errors.description}</p>}

                            <input
                                type="text"
                                name="motivation"
                                placeholder="Motivación"
                                defaultValue={selectedTip?.Frase_D_Dia || ''}
                                maxLength={MAX_MOTIVATION_CHARACTERS}
                            />
                            {errors.motivation && <p className="tip-error-text">{errors.motivation}</p>}

                            <button className="tip-btn-save" type="submit">{selectedTip ? 'Guardar Cambios' : 'Crear Tip'}</button>
                            <button className="tip-btn-close" type="button" onClick={closeModals}>Cerrar</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TipsAdmin;
