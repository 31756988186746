import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { resetEncuestas, fetchEncuestas } from '../../Services/encuestaServices'; // Usamos fetchEncuestas desde el servicio
import './EncuestasAdmin.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EncuestasChart = () => {
  const [calificaciones, setCalificaciones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const obtenerEncuestas = async () => {
      try {
        const data = await fetchEncuestas(); // Llamada al servicio
        setCalificaciones(data);
      } catch (error) {
        console.error('Error al obtener las encuestas:', error);
      } finally {
        setLoading(false);
      }
    };
  
    obtenerEncuestas();
  }, []);

  const agruparCalificaciones = (calificaciones, campo) => {
    const conteo = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    calificaciones.forEach(encuesta => {
      const calificacion = encuesta[campo];
      if (conteo[calificacion] !== undefined) {
        conteo[calificacion] += 1;
      }
    });
    return Object.values(conteo);
  };

  const handleResetEncuestas = async () => {
    try {
      await resetEncuestas();
      setCalificaciones([]);
      alert('Encuestas reseteadas exitosamente');
    } catch (error) {
      console.error('Error al resetear encuestas:', error);
      alert('Hubo un problema al resetear las encuestas');
    }
  };

  if (loading) {
    return <div>Cargando estadísticas...</div>;
  }

  if (calificaciones.length === 0) {
    return <div>No hay datos disponibles para mostrar.</div>;
  }

  const servicioData = agruparCalificaciones(calificaciones, 'calificacionServicio');
  const productosData = agruparCalificaciones(calificaciones, 'calificacionProductos');
  const tiempoEsperaData = agruparCalificaciones(calificaciones, 'calificacionTiempoEspera');
  const experienciaData = agruparCalificaciones(calificaciones, 'calificacionExperiencia');

  const labels = ['1 - Muy Baja', '2 - Baja', '3 - Media', '4 - Buena', '5 - Muy Buena'];

  const dataForChart = (label, dataset, colors) => ({
    labels: labels,
    datasets: [
      {
        label: label,
        data: dataset,
        backgroundColor: colors,
        borderColor: colors.map(color => color.replace('0.2', '1')),
        borderWidth: 1,
      }
    ]
  });

  const options = {
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2.5,
    plugins: {
        legend: {
            display: false, // Oculta la leyenda
        },
    },
};

  const colores = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(54, 162, 235, 0.2)'
  ];

  return (
    <div className="encuestas-admin">
      <h1>Gráficas de Encuestas Agrupadas</h1>
      <button className="btn-reset" onClick={handleResetEncuestas}>Resetear Encuestas</button>

      <div className="chart-container">
        <h3>Calificación de Servicio</h3>
        <Bar data={dataForChart('Calificación Servicio', servicioData, colores)} options={options} />
      </div>

      <div className="chart-container">
        <h3>Calificación de Productos</h3>
        <Bar data={dataForChart('Calificación Productos', productosData, colores)} options={options} />
      </div>

      <div className="chart-container">
        <h3>Calificación de Tiempo de Espera</h3>
        <Bar data={dataForChart('Calificación Tiempo de Espera', tiempoEsperaData, colores)} options={options} />
      </div>

      <div className="chart-container">
        <h3>Calificación de Experiencia</h3>
        <Bar data={dataForChart('Calificación Experiencia', experienciaData, colores)} options={options} />
      </div>
    </div>
  );
};

export default EncuestasChart;
