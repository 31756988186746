import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebaseConfig'; // Asegúrate de que esta ruta apunte correctamente a tu configuración de Firebase
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Usa la URL base del backend
const API_URL = `${API_BASE_URL}/api/servicios`;

// Obtener todos los servicios
export const fetchServicios = async () => {
    try {
        const response = await axios.get(API_URL);
        console.log('Servicios obtenidos:', response.data); // Verifica los datos recibidos
        return response.data;
    } catch (error) {
        console.error('Error al obtener servicios:', error);
        throw error;
    }
};

// Subir imagen a Firebase Storage
const uploadImageToFirebase = async (imageFile) => {
    if (!imageFile) return null;

    const imageRef = ref(storage, `servicios/${imageFile.name}`);
    const snapshot = await uploadBytes(imageRef, imageFile);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
};

// Crear un nuevo servicio con imagen y categoría
export const createServicio = async (servicio, imageFile) => {
    try {
        const imageUrl = await uploadImageToFirebase(imageFile);
        const servicioWithImage = { 
            ...servicio, 
            Imagen_Url: imageUrl, 
            idCategoriaServicio: Number(servicio.idCategoriaServicio) // Asegúrate de convertir a número
        };
        const response = await axios.post(API_URL, servicioWithImage);
        return response.data;
    } catch (error) {
        console.error('Error al crear servicio:', error);
        throw error;
    }
};

// Actualizar un servicio existente con imagen y categoría
export const updateServicio = async (id, servicio, imageFile) => {
    try {
        let imageUrl = servicio.Imagen_Url;

        if (imageFile) {
            imageUrl = await uploadImageToFirebase(imageFile);
        }

        const servicioWithImage = { 
            ...servicio, 
            Imagen_Url: imageUrl, 
            idCategoriaServicio: Number(servicio.idCategoriaServicio) // Asegúrate de convertir a número
        };
        const response = await axios.put(`${API_URL}/${id}`, servicioWithImage);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar servicio:', error);
        throw error;
    }
};

// Eliminar un servicio
export const deleteServicio = async (id) => {
    try {
        await axios.delete(`${API_URL}/${id}`);
    } catch (error) {
        console.error('Error al eliminar servicio:', error);
        throw error;
    }
};
