import React, { useState, useEffect } from 'react';
import { fetchContactos } from '../../../Services/contactosServices';  // Asegúrate de que la ruta sea correcta
import './Contacto.css';
import facebookIcon from '../../../assets/imagenes/FacebookR.png';
import instagramIcon from '../../../assets/imagenes/InstagramR.png';
import whatsappIcon from '../../../assets/imagenes/WhatsappR.png';
import tiktokIcon from '../../../assets/imagenes/TiktokR.png';
import handImage from '../../../assets/imagenes/Hand.png';

const Contacto = () => {
    const [contactos, setContactos] = useState({
        facebook: '',
        instagram: '',
        whatsapp: '',
        tiktok: ''
    });

    // Helper function to ensure URLs have a valid prefix
    const ensureUrlPrefix = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    };

    // Function to format the WhatsApp link if it's a phone number
    const formatWhatsAppLink = (whatsapp) => {
        // Check if it's a number (simple check)
        const isPhoneNumber = /^\d+$/.test(whatsapp);

        if (isPhoneNumber) {
            // Create the WhatsApp API link with the phone number
            return `https://wa.me/${whatsapp}`;
        }
        
        // If it's already a full link, ensure it has the correct prefix
        return ensureUrlPrefix(whatsapp);
    };

    // Cargar los contactos desde el backend cuando se monte el componente
    useEffect(() => {
        const loadContactos = async () => {
            try {
                const contactosData = await fetchContactos();
                const contactosMap = {};
                contactosData.forEach(contacto => {
                    const lowerCaseName = contacto.NombreContacto.toLowerCase();
                    contactosMap[lowerCaseName] = lowerCaseName === 'whatsapp'
                        ? formatWhatsAppLink(contacto.Enlace)  // Aplicar formateo especial para WhatsApp
                        : ensureUrlPrefix(contacto.Enlace);
                });
                setContactos(contactosMap);
            } catch (error) {
                console.error('Error al cargar los contactos:', error);
            }
        };

        loadContactos();
    }, []);

    return (
        <div className="contacto-container">
            <h2 className="contacto-title">Contacto</h2>
            <h3 className="contacto-subtitle">Síguenos en nuestras redes sociales</h3>
            <div className="contacto-content">
                <div className="contacto-texto">
                    <h3>Nuestras Redes Sociales</h3>
                    <p>
                        Conéctate con nosotros, síguenos en nuestras redes sociales para <br /> estar al tanto de las últimas novedades,
                        promociones y mucho más. <br />Estamos aquí para ayudarte.
                    </p>
                    <div className="contacto-icons">
                        {contactos.facebook && (
                            <a href={contactos.facebook} target="_blank" rel="noopener noreferrer">
                                <img src={facebookIcon} alt="Facebook" />
                            </a>
                        )}
                        {contactos.instagram && (
                            <a href={contactos.instagram} target="_blank" rel="noopener noreferrer">
                                <img src={instagramIcon} alt="Instagram" />
                            </a>
                        )}
                        {contactos.whatsapp && (
                            <a href={contactos.whatsapp} target="_blank" rel="noopener noreferrer">
                                <img src={whatsappIcon} alt="WhatsApp" />
                            </a>
                        )}
                        {contactos.tiktok && (
                            <a href={contactos.tiktok} target="_blank" rel="noopener noreferrer">
                                <img src={tiktokIcon} alt="Tiktok" />
                            </a>
                        )}
                    </div>
                </div>
                <img src={handImage} alt="Hand holding phone" className="contacto-imagen" />
            </div>
        </div>
    );
};

export default Contacto;
