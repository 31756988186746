import React, { useState, useEffect } from 'react';
import { fetchNosotrosData, updateNosotrosData } from '../../Services/nosotrosServices';
import { storage } from '../../config/firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './NosotrosAdmin.css';

const MAX_WORDS_TITLE = 10;
const MAX_CHARACTERS_TITLE = 50;
const MAX_WORDS_NAME = 5;
const MAX_CHARACTERS_NAME = 30;
const MAX_WORDS_DESCRIPTION = 30;
const MAX_CHARACTERS_DESCRIPTION = 250;

const NosotrosAdmin = () => {
  const [nosotros, setNosotros] = useState({
    idNosotros: null,
    Titulo: '',
    Nombre: '',
    Descripcion: '',
    imagenUrl: ''
  });
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loadNosotrosData = async () => {
      try {
        const data = await fetchNosotrosData();
        if (data.length > 0) {
          setNosotros(data[0]);
        }
      } catch (error) {
        console.error('Error al cargar los datos de Nosotros:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadNosotrosData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    let error = '';

    if (name === 'Titulo') {
      const words = newValue.split(' ').slice(0, MAX_WORDS_TITLE).join(' ');
      newValue = words.length <= MAX_CHARACTERS_TITLE ? words : newValue.slice(0, MAX_CHARACTERS_TITLE);
      if (words.split(' ').length > MAX_WORDS_TITLE || newValue.length > MAX_CHARACTERS_TITLE) {
        error = `Máximo ${MAX_WORDS_TITLE} palabras y ${MAX_CHARACTERS_TITLE} caracteres permitidos.`;
      }
    } else if (name === 'Nombre') {
      const words = newValue.split(' ').slice(0, MAX_WORDS_NAME).join(' ');
      newValue = words.length <= MAX_CHARACTERS_NAME ? words : newValue.slice(0, MAX_CHARACTERS_NAME);
      if (words.split(' ').length > MAX_WORDS_NAME || newValue.length > MAX_CHARACTERS_NAME) {
        error = `Máximo ${MAX_WORDS_NAME} palabras y ${MAX_CHARACTERS_NAME} caracteres permitidos.`;
      }
    } else if (name === 'Descripcion') {
      const words = newValue.split(' ').slice(0, MAX_WORDS_DESCRIPTION).join(' ');
      newValue = words.length <= MAX_CHARACTERS_DESCRIPTION ? words : newValue.slice(0, MAX_CHARACTERS_DESCRIPTION);
      if (words.split(' ').length > MAX_WORDS_DESCRIPTION || newValue.length > MAX_CHARACTERS_DESCRIPTION) {
        error = `Máximo ${MAX_WORDS_DESCRIPTION} palabras y ${MAX_CHARACTERS_DESCRIPTION} caracteres permitidos.`;
      }
    }

    setNosotros({ ...nosotros, [name]: newValue });
    setErrors({ ...errors, [name]: error });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const uploadImageToFirebase = async (file) => {
    if (!file) return null;

    const imageRef = ref(storage, `nosotros/${file.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error subiendo la imagen:', error);
      return null;
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    let updatedData = { ...nosotros };
    if (imageFile) {
      const imageUrl = await uploadImageToFirebase(imageFile);
      if (imageUrl) {
        updatedData.imagenUrl = imageUrl;
      }
    }

    try {
      await updateNosotrosData(updatedData); 
      alert('Datos actualizados correctamente');
    } catch (error) {
      console.error('Error al actualizar los cambios:', error);
      alert('Error al actualizar los cambios');
    }
  };

  if (isLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="nosotros-admin-container">
      <h2 className="nosotros-admin-title">Editar Nosotros</h2>
      <form className="nosotros-form" onSubmit={handleSaveChanges}>
        <div className="form-group">
          <label htmlFor="Titulo">Título</label>
          <input
            type="text"
            name="Titulo"
            value={nosotros.Titulo || ''}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Título"
          />
          {errors.Titulo && <p className="error-text">{errors.Titulo}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="Nombre">Nombre</label>
          <input
            type="text"
            name="Nombre"
            value={nosotros.Nombre || ''}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Nombre"
          />
          {errors.Nombre && <p className="error-text">{errors.Nombre}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="Descripcion">Descripción</label>
          <textarea
            name="Descripcion"
            value={nosotros.Descripcion || ''}
            onChange={handleInputChange}
            className="form-textarea"
            placeholder="Descripción"
          ></textarea>
          {errors.Descripcion && <p className="error-text">{errors.Descripcion}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="image">Imagen</label>
          <input type="file" onChange={handleImageChange} className="form-input" />
          {nosotros.imagenUrl && (
            <img src={nosotros.imagenUrl} alt="Imagen actual" className="profile-image" />
          )}
        </div>
        <button type="submit" className="save-button">Guardar Cambios</button>
      </form>
    </div>
  );
};

export default NosotrosAdmin;
