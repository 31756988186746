// src/Services/categoriaServices.js
import API_BASE_URL from './apiConfig';

// Obtener todas las categorías según el estado (activo o inactivo)
export const fetchCategorias = async (estado) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes?estado=${estado}`);
        if (!response.ok) {
            throw new Error('Error al obtener las categorías');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

// Crear una nueva categoría de imágenes
export const createCategoria = async (categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al crear la categoría');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating category:', error);
        throw error;
    }
};

// Actualizar una categoría existente
export const updateCategoria = async (id, categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al actualizar la categoría');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating category:', error);
        throw error;
    }
};

// Desactivar (borrado lógico) una categoría (cambia el estado a 0)
export const deactivateCategoria = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes/${id}/borrado-logico`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al desactivar la categoría');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deactivating category:', error);
        throw error;
    }
};

// Activar una categoría inactiva (cambia el estado a 1)
export const activateCategoria = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes/${id}/activar`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al activar la categoría');
        }
        return await response.json();
    } catch (error) {
        console.error('Error activating category:', error);
        throw error;
    }
};

// Eliminar una categoría de manera permanente
export const deleteCategoria = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriaImagenes/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Error al eliminar la categoría');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting category:', error);
        throw error;
    }
};
