// src/Services/nosotrosServices.js
import axios from 'axios';
import API_BASE_URL from './apiConfig'; // Importa la URL base desde apiConfig

// Define la URL de "Nosotros" usando la base
const API_URL = `${API_BASE_URL}/api/nosotros`;

// Función para obtener los datos de "Nosotros"
export const fetchNosotrosData = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los datos de Nosotros:', error);
        throw error;
    }
};

// Función para crear un nuevo registro de "Nosotros"
export const createNosotrosData = async (data) => {
    try {
        const response = await axios.post(API_URL, data);
        return response.data;
    } catch (error) {
        console.error('Error al crear los datos de Nosotros:', error);
        throw error;
    }
};

// Función para actualizar un registro existente de "Nosotros"
export const updateNosotrosData = async (data) => {
    try {
        const response = await axios.put(`${API_URL}/${data.idNosotros}`, data);
        return response.data;
    } catch (error) {
        console.error('Error al actualizar los datos de Nosotros:', error);
        throw error;
    }
};
