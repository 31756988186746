// src/Services/tipsServices.js
import API_BASE_URL from './apiConfig';

// Obtener todos los tips
export const fetchTips = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/tips`); // Añade el prefijo '/api' si tu backend lo usa
        if (!response.ok) {
            throw new Error('Error al obtener los tips');
        }
        return await response.json();
    } catch (error) {
        console.error('Fetch Tips Error:', error);
        throw error;
    }
};

// Crear un nuevo tip
export const createTip = async (tip) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/tips`, { // Añade '/api' si tu backend lo usa
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tip),
        });
        if (!response.ok) {
            throw new Error('Error al crear el tip');
        }
        return await response.json();
    } catch (error) {
        console.error('Create Tip Error:', error);
        throw error;
    }
};

// Actualizar un tip existente
export const updateTip = async (id, tip) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/tips/${id}`, { // Añade '/api' si tu backend lo usa
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tip),
        });
        if (!response.ok) {
            throw new Error('Error al actualizar el tip');
        }
        return await response.json();
    } catch (error) {
        console.error('Update Tip Error:', error);
        throw error;
    }
};

// Eliminar un tip
export const deleteTip = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/tips/${id}`, { // Añade '/api' si tu backend lo usa
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Error al eliminar el tip');
        }
        return await response.json();
    } catch (error) {
        console.error('Delete Tip Error:', error);
        throw error;
    }
};
