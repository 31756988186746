import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Hero.css';
import heroImage1 from '../../../assets/imagenes/Herov5Ultra.png';

const Hero = () => {
    return (
        <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false}>
            <div className="carousel-slide">
                <div className="hero-text">
                    <h1>Tu Asombroso</h1>
                    <h2>Nuevo Look</h2>
                    <p>B i e n v e n i d @ s . . .  <br /> <span> A l &nbsp;&nbsp; S a l o n &nbsp;&nbsp; d e &nbsp;&nbsp;M a r y</span></p>
                    {/* Cambiado para hacer scroll hacia la galería */}
                    <a href="#galeria" className="btn-hero">Ver más</a>
                    <p className="hero-subtext">Especialista en pelo y belleza</p>
                </div>
                <img src={heroImage1} alt="Hero" className="hero-image" />
            </div>
        </Carousel>
    );
}

export default Hero;
