import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../Services/authServices';
import { fetchUsuarioByEmail } from '../../Services/usuariosServices';
import emailjs from '@emailjs/browser';
import './Login.css';
import logo from '../../assets/imagenes/logolmao.png';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [recoveryEmail, setRecoveryEmail] = useState('');
    const [recoveryMessage, setRecoveryMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Estado para deshabilitar el botón

    // Manejo de recuperación de contraseña
    const handlePasswordRecovery = async (e) => {
        e.preventDefault();

        if (!recoveryEmail) {
            setRecoveryMessage('Por favor, ingresa un correo electrónico válido.');
            return;
        }

        try {
            const usuario = await fetchUsuarioByEmail(recoveryEmail);

            if (usuario) {
                await emailjs.send(
                    'service_c07uget', // Tu Service ID de EmailJS
                    'template_kp0tf68', // Tu Template ID de EmailJS
                    {
                        to_email: usuario.Email,
                        to_name: usuario.Username,
                        password: usuario.Contrasena,
                    },
                    'AAeeEoBwVVwUnZqA-' // Public Key de EmailJS
                );

                setRecoveryMessage('Correo de recuperación enviado con éxito. Revisa tu bandeja de entrada.');
                setIsButtonDisabled(true); // Deshabilitar el botón
            } else {
                setRecoveryMessage('No se encontró un usuario con ese correo.');
            }
        } catch (error) {
            console.error('Error al recuperar la contraseña:', error);
            setRecoveryMessage('Hubo un error al procesar tu solicitud. Intenta nuevamente.');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setRecoveryEmail(''); // Limpiar el correo ingresado
        setRecoveryMessage(''); // Limpiar mensajes
        setIsButtonDisabled(false); // Rehabilitar el botón
    };

    // Manejo del login
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { isAuthenticated, message } = await login(email, password);

            if (isAuthenticated) {
                navigate('/admin'); // Redirige al dashboard si el login es exitoso
            } else {
                setError(message || 'Correo o contraseña incorrectos.');
            }
        } catch (error) {
            setError('Error en la autenticación: ' + error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={logo} alt="Logo" className="login-logo" />
                <h2 className="login-title">Iniciar Sesión</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="login-input-container">
                        <label htmlFor="email">Correo Electrónico</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="login-input-container">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Ingresar</button>
                </form>
                <p
                    className="forgot-password-link"
                    onClick={() => setShowModal(true)}
                >
                    ¿Has olvidado tu contraseña?
                </p>
            </div>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-icon">
                            <img src={logo} alt="Logo" />
                        </div>
                        <h2>Recuperar Contraseña</h2>
                        <form onSubmit={handlePasswordRecovery}>
                            <div className="modal-input-container">
                                <label htmlFor="recovery-email">Correo Electrónico</label>
                                <input
                                    type="email"
                                    id="recovery-email"
                                    value={recoveryEmail}
                                    onChange={(e) => setRecoveryEmail(e.target.value)}
                                    required
                                    disabled={isButtonDisabled} // Deshabilitar el input si el botón está deshabilitado
                                />
                            </div>
                            <div className="modal-buttons-container">
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={handleCloseModal}
                                >
                                    Cerrar
                                </button>
                                <button
                                    type="submit"
                                    className="modal-button"
                                    disabled={isButtonDisabled} // Deshabilitar el botón
                                >
                                    {isButtonDisabled ? 'Enviado' : 'Enviar'}
                                </button>
                            </div>
                        </form>
                        {recoveryMessage && (
                            <p className="modal-message">{recoveryMessage}</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
