import React, { useState } from 'react';
import './EncuestaModal.css';
import { submitEncuesta } from '../../../Services/encuestaServices';

const EncuestaModal = ({ isOpen, closeModal }) => {
    const [formData, setFormData] = useState({
        servicio: '1',
        productos: '1',
        tiempo: '1',
        experiencia: '1',
    });

    const [mensaje, setMensaje] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Estado para desactivar el botón
    const [isSubmitted, setIsSubmitted] = useState(false); // Estado para indicar que fue enviado

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const encuestaData = {
            calificacionServicio: Number(formData.servicio),
            calificacionProductos: Number(formData.productos),
            calificacionTiempoEspera: Number(formData.tiempo),
            calificacionExperiencia: Number(formData.experiencia),
        };

        try {
            setIsSubmitting(true); // Desactivar el botón de enviar
            await submitEncuesta(encuestaData);
            setMensaje('¡Encuesta enviada exitosamente!');
            setIsSubmitted(true); // Indicar que fue enviada
        } catch (error) {
            setMensaje('Error al enviar la encuesta. Inténtalo de nuevo.');
        }
    };

    const handleClose = () => {
        setIsSubmitting(false); // Resetear el estado del botón
        setIsSubmitted(false); // Resetear el estado de enviado
        setMensaje(''); // Limpiar el mensaje
        closeModal(); // Cerrar el modal
    };

    if (!isOpen) return null;

    return (
        <div className="encuesta-modal-overlay">
            <div className="encuesta-modal">
                <h3>Encuesta de Satisfacción</h3>
                {mensaje && <p>{mensaje}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="encuesta-form-group">
                        <label htmlFor="servicio">Calificación del Servicio</label>
                        <select id="servicio" name="servicio" value={formData.servicio} onChange={handleChange}>
                            <option value="1">1 - Muy malo</option>
                            <option value="2">2 - Malo</option>
                            <option value="3">3 - Regular</option>
                            <option value="4">4 - Bueno</option>
                            <option value="5">5 - Excelente</option>
                        </select>
                    </div>
                    <div className="encuesta-form-group">
                        <label htmlFor="productos">Calificación de los Productos</label>
                        <select id="productos" name="productos" value={formData.productos} onChange={handleChange}>
                            <option value="1">1 - Muy malo</option>
                            <option value="2">2 - Malo</option>
                            <option value="3">3 - Regular</option>
                            <option value="4">4 - Bueno</option>
                            <option value="5">5 - Excelente</option>
                        </select>
                    </div>
                    <div className="encuesta-form-group">
                        <label htmlFor="tiempo">Calificación del Tiempo de Espera</label>
                        <select id="tiempo" name="tiempo" value={formData.tiempo} onChange={handleChange}>
                            <option value="1">1 - Muy malo</option>
                            <option value="2">2 - Malo</option>
                            <option value="3">3 - Regular</option>
                            <option value="4">4 - Bueno</option>
                            <option value="5">5 - Excelente</option>
                        </select>
                    </div>
                    <div className="encuesta-form-group">
                        <label htmlFor="experiencia">Calificación de la Experiencia General</label>
                        <select id="experiencia" name="experiencia" value={formData.experiencia} onChange={handleChange}>
                            <option value="1">1 - Muy malo</option>
                            <option value="2">2 - Malo</option>
                            <option value="3">3 - Regular</option>
                            <option value="4">4 - Bueno</option>
                            <option value="5">5 - Excelente</option>
                        </select>
                    </div>
                    <div className="encuesta-button-group">
                        <button
                            type="submit"
                            className={`btn ${isSubmitted ? 'btn-success' : ''}`}
                            disabled={isSubmitting || isSubmitted}
                        >
                            {isSubmitted ? 'Enviado' : isSubmitting ? 'Enviando...' : 'Enviar'}
                        </button>
                        <button type="button" className="btn" onClick={handleClose}>
                            Cerrar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EncuestaModal;
