// src/Services/categoriaProductosServices.js
import API_BASE_URL from './apiConfig';

// Obtener todas las categorías de productos según el estado (activo o inactivo)
export const fetchCategoriasProductos = async (estado) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos?estado=${estado}`);
        if (!response.ok) {
            throw new Error('Error al obtener las categorías de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching product categories:', error);
        throw error;
    }
};

// Crear una nueva categoría de productos
export const createCategoriaProducto = async (categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al crear la categoría de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating product category:', error);
        throw error;
    }
};

// Desactivar una categoría de productos (borrado lógico)
export const deactivateCategoriaProducto = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos/${id}/borrado-logico`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al desactivar la categoría de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deactivating product category:', error);
        throw error;
    }
};

// Activar una categoría de productos inactiva
export const activateCategoriaProducto = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos/${id}/activar`, {
            method: 'PUT',
        });
        if (!response.ok) {
            throw new Error('Error al activar la categoría de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error activating product category:', error);
        throw error;
    }
};

// Eliminar una categoría de productos
export const deleteCategoriaProducto = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Error al eliminar la categoría de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error deleting product category:', error);
        throw error;
    }
};

// Actualizar una categoría de productos por ID
export const updateCategoriaProducto = async (id, categoria) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/categoriasProductos/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoria),
        });
        if (!response.ok) {
            throw new Error('Error al actualizar la categoría de productos');
        }
        return await response.json();
    } catch (error) {
        console.error('Error updating product category:', error);
        throw error;
    }
};
