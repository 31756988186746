import React from 'react';
import './ServicioModal.css';

const ServicioModal = ({ servicio, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <button className="close-button" onClick={onClose}>✖</button>
                <div className="modal-content">
                    <img src={servicio.Imagen_Url} alt={servicio.NombreServicio} className="modal-imagen" />
                    <h3 className="modal-title">{servicio.NombreServicio}</h3>
                    <p className="modal-descripcion">{servicio.Descripcion}</p>
                    <p className="modal-precio">Precio: ${servicio.Precio}</p>
                </div>
            </div>
        </div>
    );
};

export default ServicioModal;
